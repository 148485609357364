import React from 'react'
import { Heading, Box } from '@chakra-ui/react'
import Img, { FluidObject } from 'gatsby-image'
import { RichText } from 'prismic-reactjs'
import Layout from '../components/layout'
import { PageProps } from 'gatsby'
import { Body } from '../slices/Slice'
import htmlSerializer from '../utils/htmlSerializer'
import SEO from '../components/seo'

export const Page: React.FC<
  PageProps<
    object,
    { title?: any; text?: any; headerimage?: { fluid: FluidObject }; body: any; seoTitle: any; seoDescription: any }
  >
> = ({ pageContext: { text, title, headerimage, body, seoTitle, seoDescription } }) => {
  console.log(body)
  return (
    <Layout>
      <SEO title={seoTitle} description={seoDescription} lang="de" />
      {headerimage.fluid && <Img fluid={headerimage.fluid} />}
      <Heading fontFamily="montserrat" marginTop="3rem" color="#855f3f">
        {title[0].text}
      </Heading>
      <Box fontFamily="merriweather" color="#151515">
        {text && <RichText htmlSerializer={htmlSerializer} render={text} />}
        <Body items={body} />
      </Box>
    </Layout>
  )
}
export default Page

import { Heading, Table, Tr, Td, Thead, Tbody, Th, Box } from '@chakra-ui/react'
import { Elements, RichText } from 'prismic-reactjs'
import { Maybe, PrismicStructuredTextType } from '../../types/graphql-types'
import React from 'react'
import htmlSerializer from '../utils/htmlSerializer'
import Layout from '../components/layout'

export interface Table3 {
  slice_type: 'table3'
  items: Table3Item[]
  primary: {
    table_title: Maybe<Pick<PrismicStructuredTextType, 'raw'>>
  }
}

export interface Table3Item {
  col2: Maybe<Pick<PrismicStructuredTextType, 'raw'>>
  col1: Maybe<Pick<PrismicStructuredTextType, 'raw'>>
  col3: Maybe<Pick<PrismicStructuredTextType, 'raw'>>
}

export interface Slice {
  slice_type: 'table2'
  items: Table2Item[]
  primary: {
    table_title: Maybe<Pick<PrismicStructuredTextType, 'raw'>>
  }
}

export interface Text {
  slice_type: 'text'
  primary: {
    text: Maybe<Pick<PrismicStructuredTextType, 'raw'>>
  }
}

export interface Table2Item {
  col2: Maybe<Pick<PrismicStructuredTextType, 'raw'>>
  col1: Maybe<Pick<PrismicStructuredTextType, 'raw'>>
}

export const Table3: React.FC<Table3> = ({ items, primary }) => {
  return (
    <Box padding="2rem 0">
      <Heading size="md">{primary.table_title.raw[0].text}</Heading>
      <Table>
        <Thead>
          <Tr>
            <Th>
              <RichText htmlSerializer={htmlSerializer} render={items[0].col1.raw} />
            </Th>
            <Th>
              <RichText htmlSerializer={htmlSerializer} render={items[0].col2.raw} />
            </Th>
            <Th>
              <RichText htmlSerializer={htmlSerializer} render={items[0].col3.raw} />
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {items.splice(1).map((item) => (
            <Tr>
              <Td>
                <RichText htmlSerializer={htmlSerializer} render={item.col1.raw} />
              </Td>
              <Td>
                <RichText htmlSerializer={htmlSerializer} render={item.col2.raw} />
              </Td>
              <Td>
                <RichText htmlSerializer={htmlSerializer} render={item.col3.raw} />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  )
}

export const Table2: React.FC<Slice> = ({ items, primary }) => {
  return (
    <Box padding="2rem 0">
      <Heading size="md">
        <RichText htmlSerializer={htmlSerializer} render={primary.table_title.raw} />
      </Heading>

      <Table>
        <Thead>
          <Tr>
            <Th>
              <RichText htmlSerializer={htmlSerializer} render={items[0].col1.raw} />
            </Th>
            <Th>
              <RichText htmlSerializer={htmlSerializer} render={items[0].col2.raw} />
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {items.splice(1).map((item) => (
            <Tr>
              <Td>
                <RichText htmlSerializer={htmlSerializer} render={item.col1.raw} />
              </Td>
              <Td>
                <RichText htmlSerializer={htmlSerializer} render={item.col2.raw} />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  )
}

export const Text: React.FC<Text> = ({ primary }) => {
  return <RichText htmlSerializer={htmlSerializer} render={primary.text.raw} />
}

export const Body: React.FC<{ items: (Slice | Table3 | Text)[] }> = ({ items }) => {
  return (
    <>
      {items.map((item) => {
        switch (item.slice_type) {
          case 'table3':
            return <Table3 {...item} />
            break
          case 'table2':
            return <Table2 {...item} />
          case 'text':
            return <Text {...item} />
        }
      })}
    </>
  )
}
